<template>
    <div class="container-modal">
        <div class="content-modal">
            <form class="form" @submit.prevent="onRiha">
                <div class="header">
                    <ion-icon v-if="selectedPlanPrice !== null && transaction_id === null" @click="selectedPlan = null, data_error = ''" :icon="$allIcons.arrowBackOutline"></ion-icon>
                    <span v-else></span>
                    <ion-icon @click="close" :icon="$allIcons.closeCircleOutline"></ion-icon>
                </div>
                <div v-if="selectedPlanPrice === null" class="form">
                    <span class="title">Hitamwo ukwo wiyandikisha</span>
                    <div v-for="item in plans" :key="item.id">
                        <input type="radio" :id="item.id" :value="item.id" v-model="selectedPlan" />
                        <span :for="item.id" v-if="item.duration === 1">Umusi {{ item.duration }} - {{ item.price }}
                            fbu</span>
                        <span :for="item.id" v-else>Imisi {{ item.duration }} - {{ item.price }} fbu</span>
                    </div>
                </div>
                <div v-else-if="selectedPlanPrice !== null && transaction_id === null" class="form">
                    <span class="title">Riha {{ selectedPlanPrice }} fbu na
                        <img loading="lazy" src="/static/lumicash.png">
                    </span>
                    <input class="input" type="text" placeholder="Numero ivako amafranga" v-model="telephone">
                    <small v-for="err in data_error?.telephone" :key="err.id">
                        {{ err }}
                    </small>
                    <small v-if="data_error !== ''">{{ data_error?.detail }}</small>
                    <small v-if="data_error !== ''">{{ data_error?.message }}</small>
                    <button class="btn-main" type="submit">{{ loading ? "Rindira..." : btn }}</button>
                </div>
                <div class="form" v-else-if="transaction_id !== null">
                    <span>Mwasabwe kwemeza kubikura {{ selectedPlanPrice }} fbu</span>
                    <span>Fyonda *163#</span>
                    <span>hama muhitemwo 2 - kubikura</span>
                    <span>hama muhitemwo 2 - kwemeza ivyakozwe.</span>
                    <span>Igikorwa: {{ transaction_id }} </span>
                    <span>Ikiringo co kwemeza gihera muminota 4</span>
                    <span class="confirm">Turarindiriye kowemeza, amafranga adushikiriye ucutangura ku raba film...</span>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

export default {
    name: "OurPayment",
    data() {
        return {
            loading: false,
            btn: 'Riha',
            plans: [],
            selectedPlan: null,
            telephone: null,
            data_error: '',
            success: '',
            transaction_id: null
        }
    },
    watch: {
        success(newValue) {
            if (newValue === null) {
                console.log(newValue);
                this.success = 'waiting';
            } else if (newValue === 'waiting') {
                setTimeout(() => {
                    console.log(newValue);
                    this.checkLogged_in();
                }, 2000);
            } else if (newValue === true) {
                console.log(newValue);
                this.success = true;
                this.$store.dispatch('triggerToast', 'Vyakunzwe wahejeje kuriha !');
                this.$store.state.isPaid = true;
                this.transaction_id = null
                this.$emit('close')
            } else if (newValue === false) {
                this.success = false;
                console.log(newValue);
                this.$store.dispatch('triggerToast', 'Vyanse ko uriha !');
                this.selectedPlan = null
                this.transaction_id = null
                this.$emit('close')
            }
        }
    },
    methods: {
        close() {
            this.$emit('close')
            this.selectedPlan = null
            this.data_error = ''
            this.telephone = null
        },
        getPlans() {
            this.loading = true
            this.axios.get(this.$store.state.baseUrl + `/plan/`)
                .then(response => {
                    this.plans = response?.data?.results
                    this.loading = false
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false
                });
        },
        onRiha() {
            let data = {
                plan: this.selectedPlan,
                telephone: this.telephone
            }
            this.loading = true
            this.data_error = ''
            this.axios.post(this.$store.state.baseUrl + `/subscriptions/`, data, this.getHeaders())
                .then((response) => {
                    this.loading = false;
                    this.success = response.data.success
                    this.transaction_id = response.data.transaction_id
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                    this.data_error = error?.response?.data
                    this.loading = false
                });
        },
    },
    computed: {
        selectedPlanPrice() {
            const selected = this.plans.find(plan => plan.id === this.selectedPlan);
            return selected ? selected.price : null;
        }
    },
    mounted() {
        this.getPlans()
    },
};
</script>

<style></style>