<template>
    <ion-page>
        <div class="toolbar">
            <span class="header-title">Ingene nagiye ndariha</span>
        </div>
        <ion-content class="ion-padding">
            <div class="container">
                <div v-if="loading" class="result">Rindira...</div>
                <div v-else-if="!billings || !billings.length" class="result">No data available</div>
                <div v-else class="account-card">
                    <ion-list v-for="item in billings" :key="item.id">
                        <ion-item detail='false' @click="UpdateNumber">
                            <div slot="start" class="start">
                                <ion-icon class="icon" :icon="$allIcons.cash"></ion-icon>
                            </div>
                            <div class="list-label">
                                <span class="list-txt">{{item.plan_name}} - {{item.plan_price}} Fbu</span>
                                <span class="sub-gray">Ikora gushika {{ moment(item.expiration_date).format('DD-MM-YYYY')}}</span>
                            </div>
                        </ion-item>
                    </ion-list>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            loading: false,
            moment: moment,
        };
    },
    computed: {
        billings() {
            return this.$store.state.billings;
        }
    },
    methods: {
        getBillings(url = null) {
            if (!url) {
                url = this.$store.state.baseUrl + '/subscriptions/';
            }
            this.loading = true
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    this.$store.state.billings = response.data.results
                    this.loading = false
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false
                });
        },
    },
    mounted() {
        this.getBillings();
    },
};
</script>